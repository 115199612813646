import { render, staticRenderFns } from "./SbPageHeader.vue?vue&type=template&id=b5ddf1ca&scoped=true"
import script from "./SbPageHeader.vue?vue&type=script&lang=js"
export * from "./SbPageHeader.vue?vue&type=script&lang=js"
import style0 from "./SbPageHeader.vue?vue&type=style&index=0&id=b5ddf1ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5ddf1ca",
  null
  
)

export default component.exports